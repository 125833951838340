import { View } from "@/ui";
import { Colors } from "@/constants";
import { getLangDir } from "@/models/utils";
import { NextSeo } from "next-seo";
import { useTranslation } from "react-i18next";
import LandingPage from "@/ui/LandingPage";

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <NextSeo title={t("seo.title")} description={t("seo.description")} />
      <View
        style={{
          direction: getLangDir(),
          flex: 1,
          backgroundColor: Colors.white,
          zIndex: 1,
        }}
      >
        <LandingPage />
      </View>
    </>
  );
}

export default Home;
